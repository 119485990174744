import { module } from 'modujs';
import ScrollObserver from '../utils/scroll';
import { lazyLoadImage } from '../utils/image';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        // Reset scroll
        window.scrollTo(0, 0)

        // Set observer
        this.initObserver()
    }

    initObserver() {
        // Create observer instance
        this.scrollObserver = new ScrollObserver({
            el: this.el,
            rootMargin: "-1px 0% -10% 0%",
            hasScrollEvent: false
        });

        this.scrollObserver.on('call', (func,way,obj,id) => {
            // Using modularJS
            this.call(func[0],{way,obj},func[1],func[2]);
        });
    }

    scrollTo(args) {
        if (this.scrollObserver && this.scrollObserver.scrollTo) {
            this.scrollObserver.scrollTo(args[0], args[1])
        }
    }

    lazyLoad(args) {
        lazyLoadImage(args.obj.target, null, () => {
            this.setData('lazy', '', args.obj.target)
        })
    }

    destroy() {
        super.destroy();
        this.scrollObserver.destroy()
    }
}
