import ClipboardJS from 'clipboard';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const $button = this.$('button')[0]

        this.clipboard = new ClipboardJS($button)

        this.onEnter = this.onEnter.bind(this)
        this.onLeave = this.onLeave.bind(this)
        this.onSuccess = this.onSuccess.bind(this)

        $button.addEventListener('pointerenter', this.onEnter)
        $button.addEventListener('pointerleave', this.onLeave)

        this.clipboard.on('success', this.onSuccess)
    }

    onEnter() {
        this.el.classList.add('is-hovered')
    }

    onLeave() {
        this.el.classList.remove('is-hovered')
    }

    onSuccess() {
        this.setTimeout && clearTimeout(this.setTimeout)
        this.el.classList.add('is-clipped')
        this.setTimeout = setTimeout(() => {
            this.el.classList.remove('is-clipped')
        }, 2000);
    }
}
