import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.bindEvents()
    }

    bindEvents() {
        this.onClickBind = this.onClick.bind(this);
        this.el.addEventListener('click', this.onClickBind)
    }

    unbindEvents() {
        this.el.addEventListener('click', this.onClickBind)
    }

    onClick(event) {
        event.preventDefault();
        const target = this.el.getAttribute('href')
        const offset = this.getData('offset') || 0

        if (!target) return

        const $targetAll = document.querySelectorAll(target)
        const $target = $targetAll[0]

        if (!$target) return

        this.call('scrollTo', [$target, {offset: offset}], 'Scroll')
    }

    destroy() {
        super.destroy()
        this.unbindEvents()
    }
}