import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.onResize = this.onResize.bind(this)
        window.addEventListener("resize", this.onResize)
        this.onResize()
    }

    onResize() {
        const $icon = this.$('icon')[0]
        const height = $icon.offsetHeight 
        document.documentElement.style.setProperty('--arrow-height', `${height}px`);
    }
}
