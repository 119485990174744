import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.isAnimating = false
        this.activeImageIndex = 0

        this.events = {
            click: {
                prev: 'onPrev',
                next: 'onNext'
            }
        }
    }

    init() {
        this.$('slide')[this.activeImageIndex].classList.add('is-active')
    }

    async onNext() {
        if (this.isAnimating) return
  
        this.isAnimating = true
  
        let targetIndex
        const prevIndex = this.activeImageIndex
  
        if (this.activeImageIndex + 1 > this.$('slide').length - 1) {
          this.activeImageIndex = targetIndex = 0
        } else {
          this.activeImageIndex = targetIndex = this.activeImageIndex + 1
        }

        this.setNextImage(targetIndex, prevIndex)
  
        await new Promise(resolve => setTimeout(resolve, 1000)) 

        this.isAnimating = false
    }
  
    async onPrev() {
        if (this.isAnimating) return

        this.isAnimating = true
  
        let targetIndex
        const prevIndex = this.activeImageIndex
  
        if (this.activeImageIndex - 1 < 0) {
          this.activeImageIndex = targetIndex = this.$('slide').length - 1
        } else {
          this.activeImageIndex = targetIndex = this.activeImageIndex - 1
        }
  
        this.setPrevImage(targetIndex, prevIndex)
  
        await new Promise(resolve => setTimeout(resolve, 1000))

        this.isAnimating = false
    }

    async setNextImage(targetIndex, prevIndex) {
        this.targetIndex = targetIndex
        await this.setCarouselClasses(targetIndex, prevIndex, 'next')
    }
  
    async setPrevImage(targetIndex, prevIndex) {
        this.targetIndex = targetIndex
        await this.setCarouselClasses(targetIndex, prevIndex, 'prev')
    }

    async setCarouselClasses(targetIndex, prevIndex, way) {
        const $targetContainer = this.$('slide')[targetIndex]
        const $prevContainer = this.$('slide')[prevIndex]
        const $lastItems = this.el.querySelectorAll('.is-last')
  
        $targetContainer.classList.remove('is-last')
        $targetContainer.classList.remove('is-last--next')
        $targetContainer.classList.remove('is-last--prev')
        $targetContainer.classList.remove('from-next')
        $targetContainer.classList.remove('from-prev')
  
        if (way === 'next') {
          $targetContainer.classList.add('from-next')
        } else if (way === 'prev') {
          $targetContainer.classList.add('from-prev')
        }
  
        for (let index = 0; index < $lastItems.length; index++) {
          const item = $lastItems[index]
          item.classList.remove('is-last')
          item.classList.remove('is-last--next')
          item.classList.remove('is-last--prev')
        }

        await new Promise(resolve => setTimeout(resolve, 50)) 
        
        $prevContainer.classList.add('is-last')
  
        if (way === 'next') {
          $prevContainer.classList.add('is-last--next')
          $prevContainer.style.transform = this.swipeTranslate
        } else if (way === 'prev') {
          $prevContainer.classList.add('is-last--prev')
          $prevContainer.style.transform = this.swipeTranslate
        }
        $prevContainer.classList.remove('is-active')
        $prevContainer.classList.remove('from-next')
        $prevContainer.classList.remove('from-prev')
  
        await new Promise(resolve => setTimeout(resolve, 50))
        $targetContainer.classList.add('is-active')
  
        await new Promise(resolve => setTimeout(resolve, 800))
        $prevContainer.style.transform = ''
    }
}
