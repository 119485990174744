import { module } from 'modujs';
import { removeFromArray } from '../utils/array';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                burger: 'toggleMenu',
                item: 'closeMenu'
            }
        }

        this.isOpen = false
        this.activeIndexes = []
    }

    init() {
        this.$items = this.$('item')
    }

    toggleMenu() {
        this.isOpen = !this.isOpen
        if (this.isOpen) {
            html.classList.add('is-menu-open')
        } else {
            html.classList.remove('is-menu-open')
        }
    }

    closeMenu() {
        if (this.isOpen) {
            html.classList.remove('is-menu-open')
            this.isOpen = false
        }
    }

    activeItem(intersectionObj) {
        

        const $item = intersectionObj.obj.target
        const itemIndex = parseInt($item.dataset.sectionIndex, 10);

        if (intersectionObj.way === 'exit') {
            removeFromArray(this.activeIndexes, itemIndex)
        } else {
            this.activeIndexes.push(itemIndex)
        }

        let index = 0
        while (index < this.$items.length) {

            const $currentItem = this.$items[index]

            if (this.activeIndexes.length && index === this.activeIndexes[0]) {
                $currentItem.classList.add('is-active')
            } else {
                $currentItem.classList.remove('is-active')
            }
            
            index++
        }
    }

    destroy() {
        super.destroy()
    }
}
