import { module } from 'modujs';
import Raf from 'quark-raf'

export default class extends module {
    constructor(m) {
        super(m);

        this.playing = false

        this.values = {
            rotateEasingX: 0,
            rotateEasingY: 0,
            offsetEasingX: [],
            offsetEasingY: [],
        }
    }

    init() {
        this.$items = this.$('plane')

        let index = 0
        while (index < this.$items.length) {
            this.values.offsetEasingX.push(0)
            this.values.offsetEasingY.push(0)
            index++
        }
    }

    toggleFollow(intersectionObj) {
        if (intersectionObj.way === "enter") {
            this.onPlayFollow()
        } else if (intersectionObj.way === "exit") {
            this.onStopFollow()
        }
    }

    onPlayFollow() {
        if (this.isPlaying) return
        this.isPlaying = true
        this.renderBind = this.render.bind(this)
        Raf.add(this.renderBind)
    }

    onStopFollow() {
        if (!this.isPlaying) return
        this.isPlaying = false
        Raf.remove(this.renderBind)
    }

    render() {
        this.translateItems()
    }

    getRotateX(value) {
        return this.values.rotateEasingX += (value - this.values.rotateEasingX) * .1
    }

    getRotateY(value) {
        return this.values.rotateEasingY += (value - this.values.rotateEasingY) * .1
    }

    getOffsetX(value, index) {
        return this.values.offsetEasingX[index] += (value - this.values.offsetEasingX[index]) * .1
    }

    getOffsetY(value, index) {
        return this.values.offsetEasingY[index] += (value - this.values.offsetEasingY[index]) * .1
    }

    translateItems() {
        let index = 0

        const rotateX = 10 * window.pointerValues.normalizedX 
        const newRotateX = this.getRotateX(rotateX)
        const rotateY = 10 * window.pointerValues.normalizedY * -1
        const newRotateY = this.getRotateY(rotateY)

        while (index < this.$items.length) {
            const $currentItem = this.$items[index]

            const offsetX = parseInt($currentItem.dataset.followOffsetX, 10) * window.pointerValues.normalizedX  || 0
            const newOffsetX = this.getOffsetX(offsetX, index)
            const offsetY = parseInt($currentItem.dataset.followOffsetY, 10) * window.pointerValues.normalizedY || 0
            const newOffsetY = this.getOffsetY(offsetY, index)

            $currentItem.style.transform = `perspective(600px) translateX(${newOffsetX}%) translateY(${newOffsetY}%) rotateX(${newRotateY}deg) rotateY(${newRotateX}deg)`;
            index++
        }
    }
}
