import FontFaceObserver from 'fontfaceobserver';
import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html } from './utils/environment';
import { map } from './utils/maths';

const app = new modular({
    modules: modules
});

window.onload = (event) => {
    const $style = document.getElementById("stylesheet");

    if ($style.isLoaded) {
        preInit();
    } else {
        $style.addEventListener('load', (event) => {
            preInit();
        });
    }
};

function preInit() {
    globals();

    fontsLoader(
        [
            'Agrandir-GrandHeavy',
            'Garnett-Light',
            'Garnett-Regular',
            'Garnett-Bold',
            'Playlist-Script',
        ],
        init
    )    
}

function init() {
    window.addEventListener('pointermove', onPointerMove);
    window.pointerValues = {
        x: 0,
        y: 0,
        normalizedX: 0,
        normalizedY: 0,
    }

    window.addEventListener('resize', onResize);
    onResize()
    
    setTimeout(() => {
        html.classList.add('is-loaded');
        html.classList.add('is-ready');
        html.classList.add("is-fonts-loaded");
        html.classList.remove('is-loading');
    }, 600)

    app.init(app);
}

async function fontsLoader(fonts, callback) {
    const fontFaceObservers = []

    if (!fonts.length) return

    fonts.forEach((fontFamily) => {
      const observer = new FontFaceObserver(fontFamily)
      fontFaceObservers.push(observer)
    })

    try {
        await Promise.all(fontFaceObservers);
        callback && callback()
    } catch (err) {
        console.warn('Some critical font are not available:', err);
    }
}

function onResize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function onPointerMove(event) {
    window.pointerValues = {
        x: event.clientX,
        y: event.clientY,
        normalizedX: map(event.clientX, 0, window.innerWidth, -1, 1),
        normalizedY: map(event.clientY, 0, window.innerHeight, -1, 1)
    }
}