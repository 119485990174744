import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.$hand = this.$('hand')[0]
        this.data = JSON.parse(this.getData('hours'))

        this.isOpen = false
    }

    init() {
        this.updateClock();
    }

    toggleClock(intersectionObj) {
        if (intersectionObj.way === "enter") {
            this.onPlayClock()
        } else if (intersectionObj.way === "exit") {
            this.onStopClock()
        }
    }

    onPlayClock() {
        if (this.isPlaying) return
        this.isPlaying = true
        this.interval = setInterval(this.updateClock.bind(this), 1000);
    }

    onStopClock() {
        if (!this.isPlaying) return
        this.isPlaying = false
        clearInterval(this.interval)
        this.interval = null
    }

    updateClock() {
        this.setClock();
        this.checkTime();
    }

    setClock() {
        const currentDate = new Date();
        const seconds = currentDate.getSeconds() / 60;
        const minutes = (seconds + currentDate.getMinutes()) / 60;
        const hours = (minutes + currentDate.getHours()) / 12;
        this.rotateClock(hours);
    }

    rotateClock(ratio) {
        this.$hand.style.setProperty("--rotate", `${ratio * 360}deg`);
    }

    checkTime() {
        const d = new Date(); // current time
        const day = d.getDay();

        const $currentDayObj = this.data.find(obj => obj.dayIndex === day)
        const AM = $currentDayObj.am
        const PM = $currentDayObj.pm

        if (AM.indexOf(null) < 0) {
            const start = new Date(AM[0].date)
            const end = new Date(AM[1].date)

            if (d >= start && d <= end) {
                if (!this.isOpen) {
                    this.el.classList.add('is-open')
                    this.isOpen = true
                }
                return
            }
        }

        if (PM.indexOf(null) < 0) {
            const start = new Date(PM[0].date)
            const end = new Date(PM[1].date)

            if (d >= start && d <= end) {
                if (!this.isOpen) {
                    this.el.classList.add('is-open')
                    this.isOpen = true
                }
                return
            }
        }

        if (this.isOpen) {
            this.el.classList.remove('is-open')
            this.isOpen = false
        }
    } 
}
