import { module } from 'modujs';
import Raf from 'quark-raf'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        // Controls
        this.direction = 1
        this.speed = 1
        
        // Offset values
        this.normalizedTranslatedValue = 0
        this.translatedValue = 0
        this.turn = 0
        this.lastTurn = 0

        // Drag States
        this.dragStart = false;
        this.dragStartValue = 0
        this.dragTranslateValue = 0
        this.oldDragTranslateValue = 0
        this.dragEasingTranslateValue = 0
        this.oldDragEasingTranslateValue = 0
        this.dragIndicatorEase = .08
        
        // Raf values
        this.start = Date.now()
        this.isPlaying = false

        // Binding
        this.bindUI()
        this.bindEvents()

        // Call
        this.initSlider()
    }

    destroy() {
        super.destroy()
        this.unbindEvents()
        this.onStopRail()
    }

    // Binding
    bindUI() {
        this.ui = {}
        this.ui.$el = this.el
        this.ui.$wrapper = this.$('wrapper')[0]
        this.ui.$items = this.$('item')
    }
    bindEvents() {
        const passiveSupport = this.getPassiveSupport()
        this.isTouchable = this.getIsTouchable()

        this.onResizeBind = this.onResize.bind(this)
        this.onDragStart = this.onDragStart.bind(this)
        this.onDrag = this.onDrag.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        
        window.addEventListener('resize', this.onResizeBind)

        this.ui.$el.addEventListener("touchstart", this.onDragStart, passiveSupport ? { passive: true } : false);
        this.ui.$el.addEventListener("touchmove", this.onDrag, passiveSupport ? { passive: true } : false);
        this.ui.$el.addEventListener("touchend", this.onDragEnd, passiveSupport ? { passive: true } : false);
        this.ui.$el.addEventListener("touchleave", this.onDragEnd, passiveSupport ? { passive: true } : false);

        this.ui.$el.addEventListener("mousedown", this.onDragStart, passiveSupport ? { passive: true } : false);
        this.ui.$el.addEventListener("mousemove", this.onDrag, passiveSupport ? { passive: true } : false);
        this.ui.$el.addEventListener("mouseup", this.onDragEnd, passiveSupport ? { passive: true } : false);
        this.ui.$el.addEventListener("mouseleave", this.onDragEnd, passiveSupport ? { passive: true } : false);
    }
    unbindEvents() {
        window.removeEventListener('resize', this.onResizeBind)

        this.ui.$el.removeEventListener("touchstart", this.onDragStart);
        this.ui.$el.removeEventListener("touchmove", this.onDrag);
        this.ui.$el.removeEventListener("touchend", this.onDragEnd);
        this.ui.$el.removeEventListener("touchleave", this.onDragEnd);

        this.ui.$el.removeEventListener("mousedown", this.onDragStart);
        this.ui.$el.removeEventListener("mousemove", this.onDrag);
        this.ui.$el.removeEventListener("mouseup", this.onDragEnd);
        this.ui.$el.removeEventListener("mouseleave", this.onDragEnd);
    }

    // Initialize
    initSlider() {
        this.computeMetrics()
    }

    // Callbacks
    onDragStart(e) {
        this.dragStart = true;
        const pointerEvent = this.isTouchable ? (e.touches[0] || e.changedTouches[0]) : e;
        this.dragStartValue = pointerEvent.clientX;

        // Add drag class
        document.documentElement.classList.add('is-dragging')
    }

    onDrag(e) {
        if (!this.dragStart) return;
        const pointerEvent = this.isTouchable ? (e.touches[0] || e.changedTouches[0]) : e;
        this.dragTranslateValue = this.oldDragTranslateValue + (pointerEvent.clientX - this.dragStartValue)
    }

    onDragEnd(e) {
        if (!this.dragStart) return;
        this.dragStart = false;

        const pointerEvent = this.isTouchable ? (e.touches[0] || e.changedTouches[0]) : e;

        if (pointerEvent.clientX < this.dragStartValue) {
            this.oldDirection = this.direction
            this.direction = -1
        } else {
            this.oldDirection = this.direction
            this.direction = 1
        }

        this.oldDragTranslateValue = this.dragTranslateValue

        // Remove drag class
        document.documentElement.classList.remove('is-dragging')
    }

    onResize() {
        this.resetTransform()
        this.computeMetrics()
    }

    toggleRail(intersectionObj) {
        if (intersectionObj.way === "enter") {
            this.onPlayRail()
        } else if (intersectionObj.way === "exit") {
            this.onStopRail()
        }
    }

    onPlayRail() {
        if (this.isPlaying) return
        this.isPlaying = true
        this.renderBind = this.render.bind(this)
        Raf.add(this.renderBind)
    }

    onStopRail() {
        if (!this.isPlaying) return
        this.isPlaying = false
        Raf.remove(this.renderBind)
    }

    // Methods
    computeMetrics() {
        this.metrics = {}
        this.metrics.elWidth = this.ui.$el.offsetWidth
        this.metrics.wrapperWidth = this.ui.$wrapper.offsetWidth
        this.metrics.itemsMetrics = Array.from(this.ui.$items).map(item => {
            const {width, left} = item.getBoundingClientRect()
            return { 
                width, 
                left: left - this.normalizedTranslatedValue
            } 
        })
    }

    setPositions(translatedValue) {
        const slideDirection = translatedValue != 0 ? translatedValue / Math.abs(translatedValue) : !this.direction
        this.turn = Math.floor(Math.abs(this.translatedValue  / this.metrics.wrapperWidth))
        this.normalizedTranslatedValue = translatedValue % (this.metrics.wrapperWidth * slideDirection)

        // Each turn => reset
        if (this.turn != this.lastTurn) {
            this.lastTurn = this.turn
            this.resetTransform()
        }

        // If direction change => reset
        if (this.oldSlideDirection != this.direction) {
            this.resetTransform()
        }

        // Loop
        let index = 0
        while (index < this.metrics.itemsMetrics.length) {
            const itemMetrics = this.metrics.itemsMetrics[index]
            const itemWidth = itemMetrics.width
            const itemLeft = itemMetrics.left
            
            // To the left
            if (slideDirection < 0) {
                const distanceToHide = itemLeft + itemWidth
                if (this.normalizedTranslatedValue < distanceToHide * slideDirection) {
                    const $item = this.ui.$items[index]
                    this.translateItem($item, slideDirection * -1)
                }

            // To the right
            } else if (slideDirection > 0) {
                const distanceToHide = this.metrics.elWidth - itemLeft
                if (this.normalizedTranslatedValue > distanceToHide) {
                    const $item = this.ui.$items[index]
                    this.translateItem($item, slideDirection * -1)
                }
            }

            index++
        }

        // Translate slider
        this.ui.$wrapper.style.transform = `translate3d(${this.normalizedTranslatedValue}px, 0, 0)`
    }

    translateItem($el, direction) {
        const translateValue = this.metrics.wrapperWidth * direction
        $el.style.transform = `translate3d(${translateValue}px, 0, 0)`
    }

    resetTransform() {
        let index = 0
        while (index < this.ui.$items.length) {
            const $item = this.ui.$items[index]
            $item.style.transform = ''
            index++
        }
    }

    getTranslateValue() {
        this.oldDragEasingTranslateValue = this.dragEasingTranslateValue
        return this.dragEasingTranslateValue += (this.dragTranslateValue - this.dragEasingTranslateValue) * this.dragIndicatorEase
    }

    // Render
    render() {
        this.updateSlider()
        this.updateDrag()
    }
    updateSlider() {
        if (this.speed === 0) return

        const time = (1 / 1000) * (Date.now() - this.start);
        
        if (time > .01) { // milisecondes
            this.start = Date.now()
            this.translatedValue += 1 * this.direction * this.speed
            this.setPositions(this.translatedValue)
        }
    }

    updateDrag() {
        const translateValue = this.getTranslateValue() - this.oldDragEasingTranslateValue
        this.translatedValue += translateValue
        this.setPositions(this.translatedValue)
    }

    // Utils
    getPassiveSupport() {
        let passiveSupported = false;
    
        try {
          const options = Object.defineProperty({}, "passive", {
            get: function () {
              passiveSupported = true;
            }
          });
    
          window.addEventListener("test", options, options);
          window.removeEventListener("test", options, options);
        } catch (err) {
          passiveSupported = false;
        }
    
        return passiveSupported
    }

    getIsTouchable() {
        // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript  
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        const mq = function (query) { return window.matchMedia(query).matches; }
    
        // @ts-ignore
        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) { return true; }
    
        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }
}