import anime from 'animejs/lib/anime.es.js';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.setAnimation()
        setTimeout(() => {
            this.startAnimation()
        }, 500)
    }

    setAnimation() {
        // Set animation
        this.timeline = anime.timeline({
            easing: 'linear',
            autoplay: false
        });

        // Get 3 colors groups
        const $groups = this.el.querySelectorAll('.js-group')

        // For each group add timelines items
        let index = 0
        const delay = 50

        while (index < $groups.length) {
            // Get paths
            const $group = $groups[index]
            const $paths = $group.querySelectorAll('.js-path')

            // Set color group animation
            this.timeline.add({
                targets: $paths,
                duration: 300,
                strokeDashoffset: [anime.setDashoffset, 0],
                delay: (el, i) => { 
                    return i * 150 
                }
            }, delay * index + 50)

            // Next group
            index++
        }
    }

    startAnimation() {
        this.timeline && this.timeline.play()
    }

    destroy() {
        super.destroy()
    }
}
