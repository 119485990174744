import { module } from 'modujs';
import MagicGrid from "magic-grid"

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        let magicGrid = new MagicGrid({
            container: this.el, // Required. Can be a class, id, or an HTMLElement.
            static: true, // Required for static content.
            animate: false, // Optional.
            gutter: 32,
            maxColumns: 2,
        });
          
        magicGrid.listen();
    }
}
